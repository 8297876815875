<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="w-100 col-lg-7 d-lg-flex brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-3"
      >
        <b-row class="mt-5 mt-lg-0">
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto pt-5 pt-lg-0"
          >
            <b-card-title class="mb-1 pt-xs-100">
              Daftar Sekarang
            </b-card-title>
            <b-card-text class="mb-2">
            Satu langkah lagi untuk menjadi bagian dari SI-PLM. Lengkapi profil anda untuk mendaftar.
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label="Nama Lengkap"
                      label-for="register-name"
                    >
                      <template v-slot:label>
                        Nama Lengkap <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Nama Lengkap"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          id="register-name"
                          v-model="userFullName"
                          name="register-name"
                          :state="errors.length > 0 ? false:null"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="HP / Telepon"
                      label-for="register-handphone"
                    >
                      <template v-slot:label>
                        HP / Telepon <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="HP / Telepon"
                        vid="handphone"
                        rules="required"
                      >
                        <cleave
                          id="register-handphone"
                          v-model="handphone"
                          name="register-handphone"
                          :raw="false"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :options="clevePhone"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                      label="Tanggal Lahir"
                      label-for="register-dob"
                    >
                      <template v-slot:label>
                        Tanggal Lahir <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Tanggal Lahir"
                        vid="register-dob"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="dob"
                          class="form-control"
                          name="register-dob"
                          placeholder="Tanggal Lahir"
                          :class="(errorsValidation && errorsValidation.dob && errorsValidation.dob.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Jenis Kelamin"
                      label-for="register-gender"
                    >
                      <template v-slot:label>
                        Jenis Kelamin <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Jenis Kelamin"
                        vid="register-gender"
                        rules="required"
                      >
                        <v-select
                          id="register-gender"
                          v-model="gender"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="genderOption"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <b-form-group
                      label-for="provinsiList"
                      label="Provinsi"
                    >
                      <template v-slot:label>
                        Provinsi <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Provinsi"
                        vid="register-provinsi"
                        rules="required"
                      >
                        <v-select
                          id="provinsiList"
                          v-model="provinsi"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="provinsiOption.options"
                          @input="(val) => changeKabKota(val)"
                          :class="(errorsValidation && errorsValidation.provinsi && errorsValidation.provinsi.value && errorsValidation.provinsi.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group
                      label-for="kabKotaList"
                      label="Kabupaten / Kota"
                    >
                      <template v-slot:label>
                        Kabupaten / Kota <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Kabupaten / Kota"
                        vid="register-kabkota"
                        rules="required"
                      >
                        <v-select
                          id="kabKotaList"
                          v-model="kabKota"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="kabkotaOption.options"
                          :class="(errorsValidation && errorsValidation.kab_kota && errorsValidation.kab_kota.value && errorsValidation.kab_kota.value.length > 0) ? 'is-invalid': ''"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                      label="Email"
                      label-for="register-email"
                    >
                      <template v-slot:label>
                        Alamat Email <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="register-email"
                          v-model="userEmail"
                          name="register-email"
                          :state="errors.length > 0 ? false:null"
                          :class="(errorsValidation && errorsValidation.email && errorsValidation.email.length > 0) ? 'is-invalid': ''"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <!-- password -->
                    <b-form-group
                      label-for="register-password"
                      label="Password"
                    >
                      <template v-slot:label>
                        Password <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="register-password"
                            v-model="password"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false:null"
                            name="register-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Saya setuju untuk mengikuti
                    <b-link v-b-modal.modal-default>syarat dan kondisi kebijakan data privasi</b-link>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Daftar Sekarang
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>Sudah memiliki akun?</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;Masuk Sekarang</span>
              </b-link>
            </p>

            <b-modal
              id="modal-default"
              ok-only
              ok-title="Saya Setuju"
              centered
              size="lg"
              title="Pernyataan persetujuan penyimpanan, penggunaan data"
            >
              <b-card-text>
                <p>SI-PLM adalah Aplikasi yang dibuat untuk pelaporan di Komisi Yudisial. Kami akan mengggunakan sumber daya yang kami miliki secara wajar untuk memastikan semua proses bisnis pekerjaan yang lakukan aman termasuk dalam pengumpulan, pengolahan, penyimpanan,penggunaan dan distribusi data.
Kebijakan Perlindungan data kami menegaskan, bahwa kami tidak akan memberikan ruang dan tidak mentolersansi segala bentuk tindakan, disengaja atau tidak disengaja yang menyebabkan rusak, hilang,penyalahgunaan,pengalihan data dan distribusi data yang tidak sesuai kebijakan.</p>
                <p> Kami memiliki kewajiban terhadap data-data pengguna yang kami simpan, sebagai berikut:</p>
                <ol>
                  <li>Dilindungi dengan system Perlindungan data secara baik (enkripsi dan mekanisme akses);</li>
                  <li>Sesuai dengan kebijakan Perlindungan data;</li>
                  <li>Mengikuti peraturan perundangan yang berlaku di Indonesia;</li>
                  <li>endapat persetujuan mengenai pengumpulan, penggunaan, distribusi dari pemilik data;</li>
                  <li>Tidak mempublikasikan data pribadi yang sensitive yang bisa digunakan untuk mengidentifikasi lokasi, dan profile pemilik data;</li>
                  <li>Hanya digunakan sesuai kesepakatan dalam persetujuan, dan penggunaan diluar tujuan awal harus mendapat ulang dari pemilik data;</li>
                  <li>Setiap data yang tidak lagi digunakan sesuai durasi kegiatan maka data akan dikembalikan kepada pemilik data atau dihancurkan sesuai ketentuan dalam kebijakan Perlindungan data.</li>
                </ol>
                <p>Setiap Pemilik data memiliki Hak:</p>
                <ol>
                  <li>Memastikan penyimpanan, penggunaan, dan distribusi data sesuai ketentuan;</li>
                  <li>Membatalkan persetujuan, meski diawal sudah memberikan persetujuan;</li>
                  <li>Meminta sebagian atau beberapa data untuk dianonimkan;</li>
                  <li>Membawa ke proses hukum di negara Republik Indonesia jika terbukti terjadi pelanggaran kebijakan atau tidak sesuai perundangan di negara RI;</li>
                </ol>
                <p>Setelah membaca hal diatas, dengan penuh kesadaran dan pemahaman saya menyatakan setuju data-data saya dipergunakan untuk kepentingan Pelaporan</p>
                <p>Untuk mengetahui lebih detail  isi kebijakan Perlindungan data privasi</p>
              </b-card-text>
            </b-modal>
          </b-col>
        </b-row>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/layouts/components/LogoDouble.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BModal, VBModal,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import {
  getProvinsi,
  getKota,
} from '@/connection-api/master'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import {
  Authregister,
} from '@/connection-api/authentication'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    BModal,
    flatPickr,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorsValidation: [],
      status: '',
      userFullName: '',
      handphone: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      dob: '',
      gender: 'Laki-laki',
      provinsi: '',
      kabKota: '',
      genderOption: ['Laki-laki', 'Perempuan', 'Tidak bersedia menjawab'],
      registerOption: ['Youth'],
      applicationToken: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'ID',
      },
      recaptcha: '',
      provinsiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
      kabkotaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih --' },
        ],
      },
    }
  },
  async mounted() {
    // this.recaptchaReload()
    // this.applicationToken = router.currentRoute.params.token
    const responsePropinsi = await getProvinsi()
    this.mappingSelectOption(responsePropinsi.data, this.provinsiOption.options)
    // console.log(this.provinsiOption.options)
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async changeKabKota(propinsiId) {
      this.kabkotaOption = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kabupaten Kota --' },
        ],
      }
      const response = await getKota({ provinsi_id: propinsiId.value })
      this.mappingSelectOption(response.data, this.kabkotaOption.options)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$swal({
        title: 'Pendaftaran Gagal!',
        html: message,
        icon: variant,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    async recaptchaReload() {
      await this.$recaptchaLoaded()

      const token = await this.$recaptcha('register')
      this.recaptcha = token
    },
    onCaptchaVerified(recaptchaToken) {
      this.resetCaptcha()
      this.recaptcha = recaptchaToken
      this.login()
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset()
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          Authregister({
            nama: this.userFullName,
            no_hp: this.handphone,
            dob: this.dob,
            gender: this.gender,
            email: this.userEmail,
            username: this.userEmail,
            password: this.password,
            password_confirmation: this.password,
            provinsi_id: this.provinsi,
            kota_id: this.kabKota,
            recaptcha: this.recaptcha,
          })
            .then(response => {
              if (response.data.status.code === '00') {
                if (this.applicationToken === '') {
                  this.$router.replace('login')
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${this.userFullName || this.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Anda telah berhasil mendaftar. Sekarang Anda dapat mulai masuk ke Aplikasi SI-PLM!',
                        },
                      })
                    })
                } else {
                  this.$router.replace({ name: 'auth-login-token', params: { token: this.applicationToken } })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Selamat Datang ${this.userFullName || this.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Anda telah berhasil mendaftar. Sekarang Anda dapat mulai masuk ke Aplikasi SI-PLM!',
                        },
                      })
                    })
                }
              } else {
                this.recaptchaReload()
                this.makeToast('danger', response.data.data)
                this.errorsValidation = response.data.errors
              }
            })
            .catch(error => {
              // this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
